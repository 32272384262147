import React from 'react'

const Login = () => {
  return (
    <div className="bg__blue-2 min-height-100">
        <section>
            <div className="container">
                <div className="text-center">
                    <img src="./../images/Group 24763.png" className='mb-4' alt="" />
                </div>
            </div>
        </section>
    </div>
  )
}

export default Login