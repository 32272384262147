import React, { useState, Fragment } from "react";

const ForgotPassword = (props) => {
    const onLogin = () => {
        localStorage.setItem("status", "login");
    }
    return (
        <Fragment>
            <div
                class={
                    "wrapper__modal-wrap modal fade " + (props.modalForgotPassword ? "show" : "")
                }
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="close__wrap pointer d-lg-none mx-auto" onClick={() => props.onCLickModalForgotPassword()}>
                    <img src="./../images/sdd.png" alt="" />
                </div>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content position-relative">
                        <div className="close__wrap pointer d-none d-lg-flex" onClick={() => props.onCLickModalForgotPassword()}>
                            <img src="./../images/sdd.png" alt="" />
                        </div>

                        <div className="text-center mb-4">
                            <div className="text-center">
                                <img src="./../images/Group 24763.png" alt="" />
                            </div>
                            <h4 className="medium font__size--32 text__32-1024 mt-3">Reset Password</h4>
                            <p className="normal font__size--16 text__16-1024 color__gray-1">Recover your account password</p>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="" className="medium font__size--14 text__14-1024">Email</label>
                            <div className="wrapper__field-wrap">
                                <input type="text" className="form-control medium font__size--16 text__16-1024" placeholder="Enter your email" />
                            </div>
                        </div>

                        <button className="btn btn__purple shadow color__white w-100 font__size--14 text__14-1024 medium rounded__12 position-relative z-2" onClick={(e) => {
                            props.onCLickSwitchModal("verificatonCode")
                        }}>Next</button>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPassword;
