import React, { Fragment } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'

const Leaderboard = () => {
    const data = [
        {
            img: "./../images/cr (1).png",
            name: "bella98",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (2).png",
            name: "lalala12",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (3).png",
            name: "robertcow",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (4).png",
            name: "andreariel",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (5).png",
            name: "khayla",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (6).png",
            name: "nananad",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (1).png",
            name: "bella98",
            star: 4,
            point: 83342,
        },
        {
            img: "./../images/cr (2).png",
            name: "lalala12",
            star: 4,
            point: 83342,
        },
        {
            img: "./../images/cr (3).png",
            name: "robertcow",
            star: 3,
            point: 83342,
        },
        {
            img: "./../images/cr (4).png",
            name: "andreariel",
            star: 3,
            point: 83342,
        },
        {
            img: "./../images/cr (5).png",
            name: "khayla",
            star: 3,
            point: 83342,
        },
        {
            img: "./../images/cr (6).png",
            name: "nananad",
            star: 2,
            point: 83342,
        },
    ]

    const showStar = (e) => {
        const save = 5 - e;
        const star = [];

        for (let index = 0; index < e; index++) {
            star.push(<img src="./../images/Stars.png" alt="" />);
        }

        if (save > 0) {
            for (let index = 0; index < save; index++) {
                star.push(<img src="./../images/Stars.png" className='opacity__4' alt="" />);
            }
        }

        return star;
    }
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"Gamgam Leaderboards"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="wrapper__offset-table">
                            <div className="auto">
                                <div className="row px-5 semi-bold font__size--24  mb-5">
                                    <div className="col-2">Rank</div>
                                    <div className="col-3">Username</div>
                                    <div className="col-4">Stars</div>
                                    <div className="col-3">Game Point</div>
                                </div>
                                {
                                    data.map((obj, i) => {
                                        return <NavLink to="/player/detail" className="wrapper__card-poin d-inline-block w-100 color__black mb-4">
                                            <div className="row">
                                                <div className="my-auto col-2 semi-bold number">{i <= 9 ? "0" : ""}{i + 1}</div>
                                                <div className="my-auto col-3">
                                                    <div className="d-flex align-items-center user">
                                                        <div className="position-relative flex-shrink-0">
                                                            {
                                                                i == 0 ? <img src="./../images/Crown.png" className='win' alt="" /> : ''
                                                            }
                                                            <img src={obj.img} alt="" />
                                                        </div>
                                                        <div className="ml-3 semi-bold font__size--24 ">
                                                            {obj.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-auto col-4">
                                                    <div className="wrapper__star">
                                                        {showStar(obj.star)}
                                                    </div>
                                                </div>
                                                <div className="my-auto col-3">
                                                    <div className="d-flex align-items-center semi-bold font__size--24 ">
                                                        <img src="./../images/dollar (1) 1.png" alt="" />
                                                        <span className='ml-2'>{obj.point}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    })
                                }
                            </div>
                        </div>


                    </div>
                </section>


                <Footer />
            </div >
        </Fragment >
    )
}

export default Leaderboard