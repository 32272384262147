import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import Characters from "./homepage/Characters";
import Leaderboard from "./homepage/Leaderboard";
import PlayerDetail from "./homepage/PlayerDetail";
import News from "./homepage/News";
import DetailNews from "./homepage/DetailNews";
import About from "./homepage/About";
import Maps from "./homepage/Maps";
import DetailMaps from "./homepage/DetailMaps";
import Spec from "./homepage/Spec";
import Faq from "./homepage/Faq";
import Terms from "./homepage/Terms";
import Privacy from "./homepage/Privacy";
import Login from "./Auth/Login";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/characters">
            <Characters />
          </Route>
          <Route exact path="/leaderboard">
            <Leaderboard />
          </Route>
          <Route exact path="/player/detail">
            <PlayerDetail />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route exact path="/news/detail">
            <DetailNews />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/maps">
            <Maps />
          </Route>
          <Route exact path="/maps/detail">
            <DetailMaps />
          </Route>
          <Route exact path="/spec">
            <Spec />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Index;
