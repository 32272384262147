import React, { Fragment } from 'react'
import { useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'
import SecBlog from "./../../component/Page/SecBlog"

const DetailNews = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <section className="position-relative">
                        <div className="container text-center">
                            <div className="text-center">
                                <p className='semi-bold font__size--18 text__18-1024 color__blue'>Game Update | 25 Jan 2023</p>
                                <h3 className='normal font__size--54 text__50-1024 text__50-sm text__50-xs mb__min-9'>How Gamgam can take your gameplay <br className='d-none d-lg-block' /> to the next level</h3>
                            </div>
                        </div>
                    </section>
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section className='pt-0 pt-sm-5'>
                    <div className="container">
                        <img src="./../images/asa (1).png" className='images__wrap-featured mb-5' alt="" />

                        <div className="position-relative">
                            <ul className="wrapper__sosmed-list d-none d-md-block">
                                <li>
                                    <a href="#!"><img src="./../images/1.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/2.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/3.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/4.png" alt="" /></a>
                                </li>
                            </ul>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-lg-7">
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Tincidunt magnis eu, vitae dictumst commodo dolor in. Aenean dictumst risus posuere a at id fermentum nibh. Luctus nunc bibendum duis egestas scelerisque. </p>
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Maecenas in pharetra hendrerit neque, tellus eu. Arcu tempus, vel blandit adipiscing a sed cursus. Augue vestibulum tempus lectus gravida condimentum mauris iaculis. Sodales imperdiet id maecenas molestie id.</p>


                                    <div className="wrapper__side-line mb-4">
                                        <p className='mb-0 medium font__size--18 text__18-1024 lh-2'>“Tincidunt magnis eu, vitae dictumst commodo dolor in. Aen ean dictumst risus posuere a at id fermentum nibh. Luctus nunc bibendum duis egestas scelerisque.”</p>
                                    </div>

                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Tincidunt magnis eu, vitae dictumst commodo dolor in. Aenean dictumst risus posuere a at id fermentum nibh. Luctus nunc bibendum duis egestas scelerisque. </p>
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Maecenas in pharetra hendrerit neque, tellus eu. Arcu tempus, vel blandit adipiscing a sed cursus. Augue vestibulum tempus lectus gravida condimentum mauris iaculis. Sodales imperdiet id maecenas molestie id.</p>


                                    <ul className='list__detail-news medium font__size--12 text__12-1024 pl-3'>
                                        <li>Id pellentesque ut pellentesque varius amet mauris. </li>
                                        <li>Tempor, risus, congue gravida nulla tincidunt. </li>
                                        <li>Tincidunt magnis eu, vitae dictumst. </li>
                                        <li>Aenean dictumst risus posuere a at id fermentum nibh.</li>
                                    </ul>

                                    <img src="./../images/asa (2).png" className='images__desc-news mb-4' alt="" />

                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Tincidunt magnis eu, vitae dictumst commodo dolor in. Aenean dictumst risus posuere a at id fermentum nibh. Luctus nunc bibendum duis egestas scelerisque. </p>
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Maecenas in pharetra hendrerit neque, tellus eu. Arcu tempus, vel blandit adipiscing a sed cursus. Augue vestibulum tempus lectus gravida condimentum mauris iaculis. Sodales imperdiet id maecenas molestie id.</p>
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Tincidunt magnis eu, vitae dictumst commodo dolor in. Aenean dictumst risus posuere a at id fermentum nibh. Luctus nunc bibendum duis egestas scelerisque. </p>
                                    <p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Maecenas in pharetra hendrerit neque, tellus eu. Arcu tempus, vel blandit adipiscing a sed cursus. Augue vestibulum tempus lectus gravida condimentum mauris iaculis. Sodales imperdiet id maecenas molestie id.</p>
                                </div>
                            </div>

                            {/* <ul className="wrapper__sosmed-list d-md-none d-block">
                                <li>
                                    <a href="#!"><img src="./../images/1.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/2.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/3.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/4.png" alt="" /></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </section>

                <SecBlog title={"Relate News"} />

                <Footer />
            </div >
        </Fragment >
    )
}

export default DetailNews