import React from 'react'
import { useRef } from 'react';
import Slider from "react-slick";

const SliderTestimoial = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (

        <Slider ref={slider1} {...settings} className='wrapper__slider-tetsimonials'>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. </p>

                    <div className="user">
                        <img src="./../images/cr (3).png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export default SliderTestimoial