import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <section className='pb-3'>
            <div className="container">
                <div className="row mb__5 sm-2">
                    <div className="mb-5 mb-md-0 col-md-2 text-center text-md-left">
                        <img src="./../images/Group 24763.png" alt="" />
                    </div>
                    <div className="mb-4 mb-md-0 col-md-5">
                        <div className="row">
                            <div className="col-4">
                                <h5 className='semi-bold font__size--14 text__14-1024 mb-3'>Menu 1</h5>
                                <ul className='list__foooter-menu'>
                                    <li>
                                        <NavLink to="/" className='medium font__size--14 text__14-1024 color__gray-1'>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" className='medium font__size--14 text__14-1024 color__gray-1'>About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/news" className='medium font__size--14 text__14-1024 color__gray-1'>News</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/leaderboard" className='medium font__size--14 text__14-1024 color__gray-1'>Leaderboard</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-4">
                                <h5 className='semi-bold font__size--14 text__14-1024 mb-3'>Menu 2</h5>
                                <ul className='list__foooter-menu'>
                                    <li>
                                        <NavLink to="/characters" className='medium font__size--14 text__14-1024 color__gray-1'>Characters</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/maps" className='medium font__size--14 text__14-1024 color__gray-1'>Maps</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq" className='medium font__size--14 text__14-1024 color__gray-1'>Support</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/spec" className='medium font__size--14 text__14-1024 color__gray-1'>Spec</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-4">
                                <h5 className='semi-bold font__size--14 text__14-1024 mb-3'>Legal</h5>
                                <ul className='list__foooter-menu'>
                                    <li>
                                        <NavLink to="/privacy" className='medium font__size--14 text__14-1024 color__gray-1'>Privacy Notice</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms" className='medium font__size--14 text__14-1024 color__gray-1'>Terms of Service</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 mb-md-0 col-6 col-md">
                        <h5 className='semi-bold font__size--14 text__14-1024 mb-3'>Download our mobile games</h5>

                        <ul className='list__foooter-menu'>
                            <li>
                                <a href="#!">
                                    <img src="./../images/safdd (2).png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#!">
                                    <img src="./../images/App Store.png" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-4 mb-md-0 col-6 col-md">
                        <h5 className='semi-bold font__size--14 text__14-1024 mb-3'>Connect with us</h5>
                        <ul className='list__foooter-sosmed'>
                            <li>
                                <a href="#!">
                                    <img src="./../images/Icon.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#!">
                                    <img src="./../images/Icon (1).png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#!">
                                    <img src="./../images/Icon (2).png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#!">
                                    <img src="./../images/Icon (3).png" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center medium font__size--14 text__14-1024">
                    © 2023 Gamgam games inc
                </div>
            </div>
        </section>
    )
}

export default Footer