import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import CardCharacter from "../../component/Card/CardCharacter";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import SecBanner from "../../component/Page/SecBanner";
import SecBlog from "../../component/Page/SecBlog";

const Index = (props) => {
  const character = [
    "./../images/Mask group (3).png",
    "./../images/Mask group (4).png",
    "./../images/Mask group (5).png",
    "./../images/Mask group (6).png",
  ]


  return (
    <Fragment>
      <div className="overflow-hidden">

        <div className="bg__blue-2">
          <Navbar />

          <section className="position-relative">
            <img src="./../images/Group 1000003200.png" className="images__head-top d-none d-lg-block" alt="" />
            <div className="container position-relative z-2">
              <div className="row">
                <div className="col-lg-6 my-auto">
                  <h1 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">Discover a New World of Fun with Gamgam!</h1>
                  <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 my-4">Experience the excitement of exploring a vast world filled with interesting characters, challenging puzzles and thrilling battles. Conquer the obstacles and uncover the secrets of Gamgam – the ultimate gaming experience!</p>

                  <a href="#!" className="d-inline-block semi-bold font__size--14 text__14-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12 mb-5">
                    <div className="d-flex align-items-center">
                      <img src="./../images/Game.png" className="mr-2" alt="" />
                      Play Free
                    </div>
                  </a>

                  <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-md-5">
                    <div className="wrapper__cicle-user d-flex align-items-center mb-3 mb-md-0">
                      <div className="items">
                        <img src="./../images/as (1).png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/as (2).png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/as (3).png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/as (4).png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/as (5).png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/as (6).png" alt="" />
                      </div>
                    </div>
                    <span className="ml-2 semi-bold font__size--20 text__20-1024">325K+ Players Online Now</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position__initial">
                <img src="./../images/Group 24789.png" className="images__about-left" alt="" />
              </div>
              <div className="col-lg-6">
                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">We Are Gamgam!</h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">Morbi massa gravida magna purus et egestas pellentesque. Nibh convallis a vitae risus. Ac volutpat metus sed vulputate natoque ut nunc interdum. Porttitor ipsum in sed arcu in. Vitae elit vestibulum consectetur quisque aliquet tellus maecenas ullamcorper purus. </p>

                <NavLink to="/about" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                  Learn The Game
                </NavLink>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">Choose Your Characters</h2>
              <NavLink to="/characters" className="medium font__size--14 text__14-1024 btn btn__outlined--purple color__purple no__opacity h__white shadow ml-auto text-capitalize rounded__12 d-none d-md-block">See All Characters</NavLink>
            </div>

            <div className="row">
              {
                character.map((obj) => {
                  return <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                    <CardCharacter data={obj} />
                  </div>
                })
              }

            </div>

            <div className="text-center">
              <NavLink to="/characters" className="medium font__size--14 text__14-1024 btn btn__outlined--purple color__purple no__opacity h__white shadow ml-auto text-capitalize rounded__12 d-md-none">See All Characters</NavLink>
            </div>
          </div>
        </section>


        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12">
                <h2 className="semi-bold font__size--64 text__65-1024 text__65-md text__65-sm mb-4">Gamgam: Get Ready for an Epic Gaming Adventure!</h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">Experience the excitement of exploring a vast world filled with interesting characters, challenging puzzles and thrilling battles. Conquer the obstacles and uncover the secrets of Gamgam – the ultimate gaming experience!.</p>

                <a href="#!" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 position__initial">
                <img src="./../images/Group 24790 (1).png" className="images__about-right" alt="" />
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">Your Maps</h2>
            </div>

            <div className="row mb-4 wrapper__row-padding justify-content-center">
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img src="./../images/Image (1).png" className="img left mb-3" alt="" />
                  <h5 className="semi-bold font__size--24 text__24-1024">Secret Forest</h5>
                </div>
              </div>
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img src="./../images/Image (2).png" className="img right mb-3" alt="" />
                  <h5 className="semi-bold font__size--24 text__24-1024">The Mystery of Mount</h5>
                </div>
              </div>
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img src="./../images/Image (3).png" className="img left mb-3" alt="" />
                  <h5 className="semi-bold font__size--24 text__24-1024">The Secret of The Dry Lake</h5>
                </div>
              </div>
            </div>

            <div className="text-center">
              <NavLink to="/characters" className="d-inline-block semi-bold font__size--16 text__16-1024 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12 mb-5">
                View All Maps
              </NavLink>
            </div>
          </div>
        </section>

        <SecBlog title={"Our Latest News"} showMore={true} />

        <SecBanner />

        <Footer />
      </div >
    </Fragment >
  );
};

export default Index;
