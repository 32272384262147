import React, { Fragment } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'
import SecBanner from "../../component/Page/SecBanner"

const DetailMaps = () => {
    const data = [
        {
            img: "./../images/cr (1).png",
            name: "bella98",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (2).png",
            name: "lalala12",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (3).png",
            name: "robertcow",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (4).png",
            name: "andreariel",
            star: 5,
            point: 83342,
        },
        {
            img: "./../images/cr (5).png",
            name: "khayla",
            star: 5,
            point: 83342,
        },
    ]

    const showStar = (e) => {
        const save = 5 - e;
        const star = [];

        for (let index = 0; index < e; index++) {
            star.push(<img src="./../images/Stars.png" alt="" />);
        }

        if (save > 0) {
            for (let index = 0; index < save; index++) {
                star.push(<img src="./../images/Stars.png" className='opacity__4' alt="" />);
            }
        }

        return star;
    }
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"Detail Maps"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center mb-4 my-md-auto">
                                <img src="./../images/Image (2).png" className="images__maps" alt="" />
                            </div>
                            <div className="col-md-6 my-auto">
                                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm text__50-xs mb-4">The Mystery of Mount</h2>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                                <a href="#!" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                                    <div className="d-flex align-items-center">
                                        <img src="./../images/Game.png" className="mr-2" alt="" />
                                        Play Free
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='pb-0'>
                    <div className="container">
                        <div className="wrapper__offset-table">
                            <div className="auto">
                                <div className="text-center mb-5">
                                    <h2 className='semi-bold font__size-60 text__60-1024 text__60-sm text__60-xxs'>Leaderboards</h2>
                                </div>
                                {
                                    data.map((obj, i) => {
                                        return <NavLink to="/player/detail" className="wrapper__card-poin d-inline-block w-100 color__black mb-4">
                                            <div className="row">
                                                <div className="my-auto col-2 semi-bold number">{i <= 9 ? "0" : ""}{i + 1}</div>
                                                <div className="my-auto col-3">
                                                    <div className="d-flex align-items-center user">
                                                        <div className="position-relative flex-shrink-0">
                                                            {
                                                                i == 0 ? <img src="./../images/Crown.png" className='win' alt="" /> : ''
                                                            }
                                                            <img src={obj.img} alt="" />
                                                        </div>
                                                        <div className="ml-3 semi-bold font__size--24 ">
                                                            {obj.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-auto col-4">
                                                    <div className="wrapper__star">
                                                        {showStar(obj.star)}
                                                    </div>
                                                </div>
                                                <div className="my-auto col-3">
                                                    <div className="d-flex align-items-center semi-bold font__size--24 ">
                                                        <img src="./../images/dollar (1) 1.png" alt="" />
                                                        <span className='ml-2'>{obj.point}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <SecBanner />

                <Footer />
            </div >
        </Fragment >
    )
}

export default DetailMaps