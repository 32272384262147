import React, { Fragment } from 'react'
import { useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'
import SecBanner from '../../component/Page/SecBanner'
import SliderTeam from '../../component/Slider/SliderTeam'
import SliderTestimoial from '../../component/Slider/SliderTestimoial'
import SecBlog from "./../../component/Page/SecBlog"

const About = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"About Gamgam"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-4 my-md-auto">
                                <img src="./../images/gfhghf.png" alt="" />
                            </div>
                            <div className="col-md-6 my-auto">
                                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">Welcome to Gamgam!</h2>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">Welcome to Gamgam, the ultimate destination for gamers of all ages! Here, you can find all of the latest and greatest games, as well as a community of like-minded gamers who love to have fun. Gamgam is the perfect place to discover new games, and with our broad selection, there’s something for everyone. We also offer a variety of ways to connect with other gamers, so you can make new friends and rivals alike. Most importantly, Gamgam is a safe and welcoming place for all gamers. We want everyone to have a great time, so we enforce a strict code of conduct to keep things fun and friendly. So come</p>

                                <a href="#!" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                                    <div className="d-flex align-items-center">
                                        <img src="./../images/Game.png" className="mr-2" alt="" />
                                        Play Free
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx'>Why Gamgam is the best adventure <br className='d-none d-md-block' /> games platform?</h2>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="wrapper__card-game">
                                    <div className="icon mb-5">
                                        <img src="./../images/Send.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--18 text__18-1024'>The Best Games!</h4>
                                    <p className='medium font__size--12 text__12-1024 lh-2 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. </p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="wrapper__card-game">
                                    <div className="icon mb-5">
                                        <img src="./../images/Star.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--18 text__18-1024'>4.9 Ratings</h4>
                                    <p className='medium font__size--12 text__12-1024 lh-2 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. </p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="wrapper__card-game">
                                    <div className="icon mb-5">
                                        <img src="./../images/Gamesd.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--18 text__18-1024'>Free Download</h4>
                                    <p className='medium font__size--12 text__12-1024 lh-2 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="text-center mb-5">
                        <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx'>Meet our team</h2>
                    </div>

                    <SliderTeam />
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx'>What They Say About Gamgam!</h2>
                        </div>

                        <SliderTestimoial />
                    </div>
                </section>

                <SecBanner />

                <Footer />
            </div >
        </Fragment >
    )
}

export default About