import React, { Fragment } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'
import SecBanner from "../../component/Page/SecBanner"

const Maps = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"Maps"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-6 mb-4 text-center my-md-auto">
                                <img src="./../images/Image (1).png" className="images__maps left" alt="" />
                            </div>
                            <div className="col-md-6 my-auto">
                                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">We Are Gamgam!</h2>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                                <NavLink to="/maps/detail" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                                    Detail Location
                                </NavLink>
                            </div>
                        </div>

                        <div className="row mb-5">

                            <div className="col-md-6 order-md-1 order-12 my-auto">
                                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">The Mystery of Mount</h2>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                                <NavLink to="/maps/detail" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                                    Detail Location
                                </NavLink>
                            </div>
                            <div className="col-md-6 order-1 order-md-12 mb-4 text-center my-md-auto">
                                <img src="./../images/Image (2).png" className="images__maps right" alt="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-4 text-center my-md-auto">
                                <img src="./../images/Image (3).png" className="images__maps left" alt="" />
                            </div>
                            <div className="col-md-6 my-auto">
                                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">The Secret of The Dry Lake</h2>
                                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. Dictum cras neque egestas sit consequat dui facilisi orci cursus. Dui vitae at nec nec. Habitant sed nisi ultricies in nisl. Arcu vitae ultricies mi ut quam sit sagittis. Sed mattis ultricies sed tellus ultrices lectus. Lorem dignissim mi ut blandit. Neque turpis pulvinar massa quis in eget.</p>
                                <NavLink to="/maps/detail" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12">
                                    Detail Location
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>

                <SecBanner />

                <Footer />
            </div >
        </Fragment >
    )
}

export default Maps