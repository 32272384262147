import React from 'react'
import { NavLink } from 'react-router-dom';
import CardBlog from "./../Card/CardBlog";

const SecBlog = (props) => {
  const blog = [
    {
      title: "How Gamgam can take your gameplay to the next level",
      sub: "Game Update",
      img: "./../images/fgh (2).png"
    },
    {
      title: "Why Gamgam is the ultimate party game",
      sub: "Game Update",
      img: "./../images/fgh (1).png"
    },
    {
      title: "The top 5 Gamgam games to beat",
      sub: "Game Update",
      img: "./../images/fgh (3).png"
    },
  ]
  return (
    <section>
      <div className="container">
        <div className="text-center mb-5">
          <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">{props.title}</h2>
        </div>

        <div className="row">
          {
            blog.map((obj) => {
              return <div className="col-md-4 mb-4">
                <CardBlog data={obj} />
              </div>
            })
          }
        </div>
        {
          props.showMore ? <div className="text-center">
            <NavLink to="/news" className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12 mb-5">
              Go to Page News
            </NavLink>
          </div> : ""
        }

      </div>
    </section>
  )
}

export default SecBlog