import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import AccordionFaq from '../../component/Accordion/AccordionFaq'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'

const Faq = () => {
    const [toogle, setToogle] = useState(-1)

    const toogleFunc = (e) => {
        if (e == toogle) {
            setToogle(-1)
        } else {
            setToogle(e)
        }
    }

    const faqData = [
        {
            title: "How to play Gamgam?",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Installation & Technical Assistance",
        },
        {
            title: "The benefits of using Gamgam ",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "How to download Gamgam",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Installation & Technical Assistance",
        },
        {
            title: "Error Codes in Gamgam",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "Evolved Weapon Skins",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "The benefits of using Gamgam ",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "How to download Gamgam",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "Error Codes in Gamgam",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "Evolved Weapon Skins",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "The benefits of using Gamgam ",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
        {
            title: "How to download Gamgam",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. Tempor, risus, congue gravida nulla tincidunt nec diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id pellentesque ut pellentesque varius amet mauris. ",
            category: "Known Issues & Fixes",
        },
    ]

    const [selcted, setSelcted] = useState("All");
    const [showing, setShowing] = useState([]);

    useEffect(() => {
        if (selcted != "All") {
            const data = faqData.filter(item => item.category == selcted);
            setShowing(data);
        } else {
            setShowing(faqData);
        }
    }, [selcted])



    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"FAQ"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="wrapper__offside-faq">
                            <div className="auto">
                                <div className="wrapper__category-faq semi-bold font__size--16 text__16-1024 mb-5">
                                    <div className={"items pointer " + (selcted == "All" ? "active" : "")} onClick={() => setSelcted("All")}>
                                        All
                                    </div>
                                    <div className={"items pointer " + (selcted == "Installation & Technical Assistance" ? "active" : "")} onClick={() => setSelcted("Installation & Technical Assistance")}>
                                        Installation & Technical Assistance
                                    </div>
                                    <div className={"items pointer " + (selcted == "Known Issues & Fixes" ? "active" : "")} onClick={() => setSelcted("Known Issues & Fixes")}>
                                        Known Issues & Fixes
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6">
                                {
                                    showing.map((obj, i) => {
                                        if (i < showing.length / 2) {
                                            return <div className="mb-4">
                                                <AccordionFaq data={obj} id={i} toogle={toogle} toogleFunc={(e) => toogleFunc(e)} />
                                            </div>
                                        }
                                    })
                                }
                            </div>
                            <div className="col-md-6">
                                {
                                    showing.map((obj, i) => {
                                        if (i >= showing.length / 2) {
                                            return <div className="mb-4">
                                                <AccordionFaq data={obj} id={i} toogle={toogle} toogleFunc={(e) => toogleFunc(e)} />
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    )
}

export default Faq