import React, { Fragment } from 'react'
import { useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const Characters = () => {
    const charcters = [
        "./../images/cr (1).png",
        "./../images/cr (2).png",
        "./../images/cr (3).png",
        "./../images/cr (4).png",
        "./../images/cr (5).png",
        "./../images/cr (6).png",
    ]

    const charctersBig = [
        "./../images/cras (1).png",
        "./../images/cras (2).png",
        "./../images/cras (3).png",
        "./../images/cras (4).png",
        "./../images/cras (5).png",
        "./../images/cras (6).png",
    ]

    const about = [
        {
            bg: "./../images/ff (1).png",
            profile: "./../images/cr (1).png",
            name: "Molli molli",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
        {
            bg: "./../images/ff (2).png",
            profile: "./../images/cr (2).png",
            name: "Kiri kiri",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
        {
            bg: "./../images/ff (3).png",
            profile: "./../images/cr (3).png",
            name: "Boy boy",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
        {
            bg: "./../images/ff (4).png",
            profile: "./../images/cr (4).png",
            name: "Moli moli",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
        {
            bg: "./../images/ff (5).png",
            profile: "./../images/cr (5).png",
            name: "Hei hei",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
        {
            bg: "./../images/ff (6).png",
            profile: "./../images/cr (6).png",
            name: "Moi moi",
            desc: "Lorem ipsum dolor sit amet consectetur. Egestas nulla fringilla posuere id tincidunt quam penatibus pharetra varius. Blandit ultrices commodo imperdiet tristique. ",
            power: ["Fly High", "Horse Power"],
        },
    ]

    const [character, setCharacter] = useState(0);
    const [characterBig, setCharacterBig] = useState(0);
    const [characterAbout, setCharacterAbout] = useState(0);

    const onChangeClick = (e) => {
        setCharacter(e);
        setCharacterBig(e);
        setCharacterAbout(e);
    }

    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <section className="position-relative">
                        <div className="d-none d-lg-block">
                            {
                                charctersBig.map((obj, i) => {
                                    if (i == characterBig) {
                                        return <img src={obj} className='images__character-side' alt="" />
                                    }
                                })
                            }
                        </div>

                        <div className="container position-relative z-2">
                            <div className="row">
                                <div className="col-lg-5 z-2">
                                </div>
                                <div className="col-lg-7 z-2">
                                    <div className="mb-5">
                                        <div className="wrapper__offsite-character">
                                            <div className="auto">
                                                <div className="wrapper__characters-lits">
                                                    {
                                                        charcters.map((obj, i) => {
                                                            return <div className="items">
                                                                <div className={"position-relative wrapper__images-icon text-center pointer " + (character == i ? "active" : "")} onClick={() => onChangeClick(i)}>
                                                                    <img src={obj} alt="" />
                                                                    <div className="dot mx-auto mt-3"></div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-block d-lg-none">
                                        {
                                            charctersBig.map((obj, i) => {
                                                if (i == characterBig) {
                                                    return <img src={obj} className='images__character-side' alt="" />
                                                }
                                            })
                                        }
                                    </div>


                                    {
                                        about.map((obj, i) => {
                                            if (i == characterAbout) {
                                                return <div className="wrapper__charter-detail">
                                                    <img src={obj.bg} className="img" alt="" />
                                                    <div className="desc ml-3 ml-sm-5">
                                                        <div className="user d-flex align-items-center mb-3">
                                                            <img src={obj.profile} alt="" />
                                                            <h5 className='ml-3 mb-0 semi-bold font__size--24'>{obj.name}</h5>
                                                        </div>
                                                        <h4 className='semi-bold font__size--20'>About me</h4>
                                                        <p className='medium font__size--12 color__gray-1 mb-4'>{obj.desc}</p>
                                                        <h4 className='semi-bold font__size--20'>My Powers</h4>
                                                        <div className="d-flex flex-wrap tag">
                                                            {
                                                                obj.power.map((k) => {
                                                                    return <div className="items normal font__size--12">
                                                                        {k}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />



                <Footer />
            </div >
        </Fragment >
    )
}

export default Characters