import React, { Fragment } from 'react'
import { useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Page/Head'
import SecBanner from "../../component/Page/SecBanner"

const Spec = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="bg__blue-2">
                    <Navbar />

                    <Head title={"Specs"} />
                </div>
                <img src="./../images/Subtract.png" className="w-100 img-cover" alt="" />


                <section>
                    <div className="container">
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-11 col-lg-9">
                                <div className="wrapper__list-spec">
                                    <div className="row text-md-center">
                                        <div className="col-md-4 mb-4 mb-md-0">
                                            <h5 className='normal mb-0 font__size--24 text__24-1024 color__white'><span className='bold'>Windows</span> 7/8/10 64-bit</h5>
                                        </div>
                                        <div className="col-md-4 mb-4 mb-md-0">
                                            <h5 className='normal mb-0 font__size--24 text__24-1024 color__white'><span className='bold'>RAM</span> 4 GB</h5>

                                        </div>
                                        <div className="col-md-4">
                                            <h5 className='normal mb-0 font__size--24 text__24-1024 color__white'><span className='bold'>VRAM</span> 1 GB</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table wrapper__table-spec semi-bold font__size--24 text__24-1024 text-center mb-4 mb-md-0">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Minumum Specs / 30 FPS</th>
                                    <th scope="col" className='d-none d__md-table-cell'>Recommended Specs / 60 FPS</th>
                                    <th scope="col" className='d-none d__md-table-cell'>High-End Specs / 144+ FPS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">CPU</th>
                                    <td className='normal font__size--20 text__20-1024'>Intel Core 2 Duo E8400 (Intel), Athlon 200GE (AMD)</td>
                                    <td className='normal font__size--20 text__20-1024 d-none d__md-table-cell'>Intel i3-4150 (Intel), Ryzen 3 1200 (AMD)</td>
                                    <td className='normal font__size--20 text__20-1024 d-none d__md-table-cell'>Intel i5-9400F 2.90GHz (Intel), Ryzen 5 2600X (AMD)</td>
                                </tr>
                                <tr>
                                    <th scope="row">GPU</th>
                                    <td className='normal font__size--20 text__20-1024'>Intel HD 4000, Radeon R5 200</td>
                                    <td className='normal font__size--20 text__20-1024 d-none d__md-table-cell'>Geforce GT 730, Radeon R7 240</td>
                                    <td className='normal font__size--20 text__20-1024 d-none d__md-table-cell'>GTX 1050 Ti, Radeon R7 370</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="d-md-none table wrapper__table-spec semi-bold font__size--24 text__24-1024 text-center mb-4 mb-md-0">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Recommended Specs / 60 FPS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">CPU</th>
                                    <td className='normal font__size--20 text__20-1024'>Intel i3-4150 (Intel), Ryzen 3 1200 (AMD)</td>
                                </tr>
                                <tr>
                                    <th scope="row">GPU</th>
                                    <td className='normal font__size--20 text__20-1024'>Geforce GT 730, Radeon R7 240</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="d-md-none table wrapper__table-spec semi-bold font__size--24 text__24-1024 text-center mb-4 mb-md-0">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">High-End Specs / 144+ FPS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">CPU</th>
                                    <td className='normal font__size--20 text__20-1024'>Intel i5-9400F 2.90GHz (Intel), Ryzen 5 2600X (AMD)</td>
                                </tr>
                                <tr>
                                    <th scope="row">GPU</th>
                                    <td className='normal font__size--20 text__20-1024'>GTX 1050 Ti, Radeon R7 370</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    )
}

export default Spec