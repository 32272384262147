import React from 'react'
import { useRef } from 'react';
import { Fragment } from 'react'
import Slider from "react-slick";

const SliderTeam = () => {
    const team = [
        {
            name: "Bessie Cooper",
            job: "Ilustrator",
            img: "./../images/BG.jpg",
        },
        {
            name: "Joni Black",
            job: "Website Development",
            img: "./../images/BG-1.jpg",
        },
        {
            name: "Darrell Steward",
            job: "Mobile Development",
            img: "./../images/BG-2.jpg",
        },
        {
            name: "Marvin McKinney",
            job: "Game Artist",
            img: "./../images/BG-3.jpg",
        },
        {
            name: "Boby Joy",
            job: "Vice President Product Management",
            img: "./../images/BG-4.jpg",
        },
        {
            name: "Bessie Cooper",
            job: "Ilustrator",
            img: "./../images/BG.jpg",
        },
        {
            name: "Joni Black",
            job: "Website Development",
            img: "./../images/BG-1.jpg",
        },
        {
            name: "Darrell Steward",
            job: "Mobile Development",
            img: "./../images/BG-2.jpg",
        },
        {
            name: "Marvin McKinney",
            job: "Game Artist",
            img: "./../images/BG-3.jpg",
        },
        {
            name: "Boby Joy",
            job: "Vice President Product Management",
            img: "./../images/BG-4.jpg",
        },
    ]

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };

    return (
        <Fragment>
            <div className="wrapper__content-offside">
                <Slider ref={slider1} {...settings} className="wrapper__slider-team-wrap">
                    {
                        team.map((obj, i) => {
                            return <div className="items">
                                <div className={"wrapper__team-user position-relative " + (i % 2 == 0 ? "left" : "right")}>
                                    <img src={obj.img} alt="" />
                                    <div className="bg"></div>
                                    <div className="desc">
                                        <h5 className='semi-bold font__size--24 text__24-1024 color__white'>{obj.name}</h5>
                                        <p className='mb-0 semi-bold font__size--14 text__14-1024 color__white'>{obj.job}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </Slider>
            </div>
        </Fragment>
    )
}

export default SliderTeam